.footer {
  color: #999;
  background: none;
  justify-content: center;
  align-items: center;
  margin: 4px;
  font-size: 10px;
  display: flex;
}

.footer a {
  color: #999;
  transition: color .2s ease-in-out;
}

.footer a:hover {
  color: #5266e7;
}

.footer a:hover:visited {
  color: #6b4a9d;
}

.app {
  height: 100vh;
  box-sizing: border-box;
  grid-template-rows: auto 1fr auto;
  grid-auto-flow: row;
  align-content: space-between;
  display: grid;
}

.SolutionReadout {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  padding: 8px;
  display: flex;
}

.SolutionReadout .solution-row {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: flex;
}

.SolutionReadout .solution-row .single-solution {
  padding: 8px;
}

.SolutionReadout .solution-row .bookend-arrows {
  font-size: 20px;
}

.ClueWorksheet {
  margin: 0;
}

.ClueWorksheet + .ClueWorksheet {
  margin-top: 8px;
}

.DoneCard .done-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.DoneCard .done-content * + * {
  margin-top: 16px;
}

.PuzzleDisplay {
  display: flex;
}

.PuzzleDisplay.loading, .PuzzleDisplay.not-found {
  justify-content: center;
  align-items: center;
}

.PuzzleDisplay.puzzle-found {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
}

.MainContent {
  display: flex;
}

.MainContent.loading, .MainContent.logged-out {
  justify-content: center;
  align-items: center;
}

/*# sourceMappingURL=index.4d7f10f4.css.map */

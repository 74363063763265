$footer-text-color: #999;

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $footer-text-color;
  font-size: 10px;
  margin: 4px;
  background: transparent;

  a {
    color: $footer-text-color;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: #5266e7;

      &:visited {
        color: #6b4a9d;
      }
    }
  }
}

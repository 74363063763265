.DoneCard {
  .done-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    * + * {
      margin-top: 16px;
    }
  }
}

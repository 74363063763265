.SolutionReadout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  padding: 8px;

  .solution-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;

    .single-solution {
      padding: 8px;
    }

    .bookend-arrows {
      font-size: 20px;
    }
  }
}

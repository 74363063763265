.app {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: auto 1fr auto;
  align-content: space-between;
  height: 100vh;
  box-sizing: border-box;

  // display: grid;
  // grid-template-columns: 1fr;
  // grid-template-rows: 1fr 12fr auto;
  // height: 100vh;
  // box-sizing: border-box;

  // .header {
  //   grid-area: 1 / 1 / 2 / 2;
  // }

  // .main {
  //   grid-area: 2 / 1 / 3 / 2;
  //   padding-bottom: 24px;
  //   padding-top: 64px;
  // }

  // .footer {
  //   grid-area: 3 / 1 / 4 / 2;
  // }
}
